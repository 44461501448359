import React from 'react';
import Layout from '../../components/Layout';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../components/Colors';
import {
  faShieldAlt,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import ArmoryMap from '../../constants/ArmoryMap';
import ArmoryFunctions from '../../components/Armory/ArmoryFunctions';

const lastUpdate = 'August 10, 2021';
const category = ArmoryMap.youtube;
const pageTitle = 'Christian YouTube Channels';
const pageDescription =
  "YouTube can be a great Godly experience.  Here's a list of the Christian YouTube channels that I watch. ";

export default () => {
  return (
    <>
      <Layout
        jumbo={true}
        pageTitle={pageTitle}
        pageDescription={pageDescription}
      >
        <h1
          css={css`
            color: ${Colors.main} !important;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 2rem;
            border-bottom: 2px solid ${Colors.main};
          `}
        >
          <FontAwesomeIcon
            css={css`
              color: ${Colors.main} !important;
              margin-right: 0.5rem;
            `}
            icon={faShieldAlt}
            size={'sm'}
            width="28"
          />
          CHRISTIAN ARMORY
        </h1>
        <p
          css={css`
            text-align: center;
            font-size: 0.8rem;
            color: ${Colors.secondary};
          `}
        >
          Last Update: {lastUpdate}
        </p>
        <h1
          css={css`
            text-align: center;
            font-size: 3rem;
            font-weight: bold;
            color: ${Colors.main};
          `}
        >
          {category.title}
        </h1>

        <p
          css={css`
            text-align: center;
            font-size: 0.85rem;
            margin: 0 auto 1rem auto;
          `}
        >
          <FontAwesomeIcon
            css={css`
              color: ${Colors.main} !important;
              margin-right: 0.25rem;
            `}
            icon={faExclamationCircle}
            size={'sm'}
            width="28"
          />
          <strong>
            I only link to things that I have used and find valuable.
          </strong>
        </p>
        <p
          css={css`
            text-align: center;
            font-size: 1.15rem;
            margin: 0 auto 1rem auto;
          `}
        >
          Armory Sections:
        </p>
        <ul
          css={css`
            list-style: none;
            text-align: center;
            font-size: 0.75rem;
            margin: 0 auto 2rem auto;
          `}
        >
          {ArmoryFunctions.renderNavItems(
            {
              title: 'ARMORY HOME',
              armoryHomeLink: true,
            },
            999
          )}
          {Object.keys(ArmoryMap).map(function(key, index) {
            return ArmoryFunctions.renderNavItems(ArmoryMap[key], index);
          })}
        </ul>
        {ArmoryFunctions.renderSectionCards(category, '0')}
      </Layout>
    </>
  );
};
